import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by" style="  color: rgb(247, 86, 86);">
   
    © 2022, Developed By  <a href="http://www.powererp.in">www.powererp.in</a>  V 2.0
    </span>
  `,
})
export class FooterComponent {

  // date last update at 23th march of 2023(by gps)
}
